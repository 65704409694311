"use client";

import Script from "next/script";
import React from "react";

interface MicrosoftUETComponentProps {
    uetId: string;
}

/**
 * Injects Microsoft UET to the page.
 * @param uetId is the measurement id for Google Analytics
 * @constructor
 */
const MicrosoftUETComponent: React.FC<MicrosoftUETComponentProps> = ({
    uetId,
}) => {
    // Right now there is a bug in Next.js where [strategy="beforeInteractive"]
    // cannot be used in the app router. See
    // https://github.com/vercel/next.js/issues/49830
    return (
        <>
            {/* eslint-disable-next-line
             @next/next/no-before-interactive-script-outside-document */}
            <Script
                strategy="beforeInteractive"
                async={true}
                id="microsoft-uet"
            >
                {`
                    (function(w,d,t,r,u)
                    {
                        var f,n,i;
                        w[u]=w[u]||[],f=function()
                        {
                            var o={ti:"${uetId}", enableAutoSpaTracking: true};
                            o.q=w[u],w[u]=new UET(o),w[u].push("pageLoad")
                        },
                        n=d.createElement(t),n.src=r,n.async=1,n.onload=n.onreadystatechange=function()
                        {
                            var s=this.readyState;
                            s&&s!=="loaded"&&s!=="complete"||(f(),n.onload=n.onreadystatechange=null)
                        },
                        i=d.getElementsByTagName(t)[0],i.parentNode.insertBefore(n,i)
                    })
                    (window,document,"script","//bat.bing.com/bat.js","uetq");
                `}
            </Script>
        </>
    );
};

export default MicrosoftUETComponent;
