"use client";

import { reportError, reportInfo } from "@/common/application/debug";
import {
    AnalyticsEventData,
    AnalyticsTools,
} from "@/features/analytics/domain/entities/AnalyticsTools";

/**
 * Implementation for the tools that are used to report analytics events.
 */
export class GoogleAnalyticsTools extends AnalyticsTools {
    reportEvent({
        eventType,
        label,
        value,
        metadata,
    }: AnalyticsEventData): Promise<void> {
        if (
            typeof window === "undefined" ||
            typeof (window as any).dataLayer === "undefined"
        ) {
            reportError(
                "Google analytics is not available, or have been disabled by the user."
            );
            return Promise.resolve();
        }

        reportInfo(
            `Reporting Google Analytics event: ${eventType} with label: ${label} and value: ${value}.`
        );

        (window as any).dataLayer.push({
            event: "analyticsEvent",
            event_type: eventType,
            event_label: label,
            event_value: value,
            ...metadata,
        });
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        // if (typeof window !== "undefined" && typeof gtag !== "undefined") {
        //
        //                    }
        //     // gtag("event", eventType, {
        //     //     event_label: label,
        //     //     event_value: value,
        //     //     ...metadata,
        //     // });
        // } else if (typeof gtag === "undefined") {
        //     reportError(
        //         "Google analytics is not available, or have been disabled by the user."
        //     );
        // }

        return Promise.resolve();
    }

    async clientId(): Promise<string | false> {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore - This is a global variable that is set by the Google Analytics script.
        return window.__googleAnalyticsClientId || false;
    }

    async sessionId(): Promise<string | false> {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore - This is a global variable that is set by the Google Analytics script.
        return window.__googleAnalyticsSessionId || false;
    }
}
